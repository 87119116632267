import 'jquery';

jQuery(document).ready(($) => {
  $(document)
    .on('click', '#long-arrow', function () {
      if( $('#pro-block').length )
      {
        $("html, body").stop(true, true).animate({ scrollTop: $("#pro-block").offset().top - 200
        }, 500);
      }

      else if( $('#tab-list').length )
      {
        $("html, body").stop(true, true).animate({ scrollTop: $("#tab-list").offset().top - 150
        }, 500);
      }
    })

    if ($('#about-block').length) {
      $('html').css('scroll-behavior', 'unset');
    }
});
